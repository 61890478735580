import React from "react"
import { Link } from "gatsby"
import { Seo } from "../components/Seo/Seo"

export default function AboutPage() {
  return (
    <React.Fragment>
      <Seo
        title="Direct mail production services, data processing, personalized printing, mail finishing and delivery services"
        description="Direct mail production services. Secure, flexible, detailed, full-service, personalized."
      />
      <div id="wrapper">
        <div id="topper">
          <div id="logo">
            <Link to="/">
              <img
                src="/wordmark-dark.svg"
                width="234"
                height="108"
                alt="Big Eye Direct"
              />
            </Link>
          </div>
          <div id="contact">
            <h2>703-955-3017</h2>
            <p>
              <a href="mailto:damon@bigeyedirect.com">info@bigeyedirect.com</a>
            </p>
          </div>
        </div>
        <div id="content">
          <div id="nav">
            <ul>
              <li>
                <Link to="/">HOME</Link>
              </li>
              <li>
                <Link to="/services/">SERVICES</Link>
              </li>
              <li>
                <Link to="/facility/">OUR FACILITY</Link>
              </li>
              <li>
                <Link to="/security/">SECURITY</Link>
              </li>
              <li className="active">
                <Link to="/about/">ABOUT US</Link>
              </li>

              <li className="endNav">
                <Link to="/contact/">CONTACT US</Link>
              </li>
            </ul>
          </div>
          <div className="clear"></div>
          <div id="flasharea">
            {" "}
            <img src="/images/photo_about.jpg" width="926" height="216" />
          </div>
          <div id="textarea">
            <h2>About BIGEYE Direct</h2>
            <h3>
              <span className="txt_color">Flexibility Matters</span>
            </h3>
            <p>
              <span className="txt_color">
                It&#8217;s often said that the devil is in the details. Nowhere
                is this more evident than in direct mail production.&nbsp; There
                are literally hundreds of ways throughout the production process
                in which costly mistakes can be made.&nbsp; Therefore we rely on
                our experience, systems, and fundamental understanding that all
                mailings, large or small, require the utmost attention to
                detail.&nbsp; While we&#8217;ve produced some of the largest
                mailing campaigns in the country (5 million pieces per week), we
                also handle mailings ranging from a couple hundred to several
                thousand pieces.&nbsp; This flexibility enables our customers to
                rely on us as their single source for direct mail
                production.&nbsp; We&#8217;ve developed a network of trusted
                partners and allies throughout the country that we can turn to
                if a particular project doesn&#8217;t fit us.&nbsp; These
                partnerships also give us flexibility to meet the demanding
                schedules of our clients.&nbsp;
              </span>
            </p>
            <h3>It's All About The Data</h3>
            <p>
              <span className="txt_color">
                Data Processing: It&#8217;s where all successful direct mail
                campaigns begin, and it&#8217;s what we do best.&nbsp; Data and
                Data Processing is the single most important part of a direct
                mail campaign, yet it&#8217;s often the most overlooked aspect
                of the project.&nbsp; At <strong>BIGEYE DIRECT</strong> we go to
                great lengths to ensure your data is as clean and accurate as
                possible.&nbsp; We&#8217;ve spent countless hours developing
                custom programs to filter and enhance data files.&nbsp; We
                understand the duplicate elimination process and execute it
                flawlessly to meet the needs of our clients.&nbsp; We run the
                most up to date address validation and presort software,
                maximizing postal discounts for all classNamees of mail.&nbsp;
                If you need a list to prospect for new customers, we have access
                to virtually every list available on the market for rent and
                provide full assistance with acquiring data that most accurately
                fits your needs.
              </span>
            </p>
            <h3>Exceptional Service Defines Us </h3>
            <p>
              We recognize that there is a printer on every corner and that
              mailers have numerous choices when seeking out production
              companies.&nbsp; It&#8217;s not enough to simply be knowledgeable
              and experienced in your industry.&nbsp; And it&#8217;s so more
              than just having the best price.&nbsp; It&#8217;s about service,
              on each and every project, day in and day out, and{" "}
              <strong>BIGEYE DIRECT</strong> provides it.&nbsp; We treat every
              client as if they are our only customer, and every project like
              it&#8217;s our last, realizing that if we don&#8217;t provide the
              service our customers demand, it will be.
            </p>
          </div>
        </div>
        <div id="footer">
          <a
            rel="noopener noreferrer"
            href="http://maps.google.com/maps?f=q&amp;source=s_q&amp;hl=en&amp;geocode=&amp;q=13860+Redskin+Drive+Herndon,+VA+2017&amp;sll=37.0625,-95.677068&amp;sspn=52.637906,88.330078&amp;ie=UTF8&amp;hq=&amp;hnear=13860+Redskin+Dr,+Herndon,+Fairfax,+Virginia+20171&amp;z=17"
            target="_blank"
          >
            13860 Redskin Drive • Herndon, VA 20171
          </a>
        </div>
        <div id="copyright">
          ©2011 BIGEYE Direct. All rights reserved. Sitemap
        </div>
      </div>
    </React.Fragment>
  )
}
